import { darken, lighten, rgba } from 'polished';

import normalizeTheme from 'react-rainbow-components/styles/helpers/normalizeTheme';

const baseTheme = {
  typography: {
    // props from https://github.com/mike-engel/styled-typography#options
    fontSizes: ['54px', '5.5rem', '2rem', '1.8rem', '1.6rem', '1.4rem'],
    desktopFontSize: {
      title: '4.6rem',
      subTitle: '2.8rem',
      body: '1.6rem',
      inputs: '1.6rem',
    },
    mobileFontSize: { title: '3rem', body: '1.5rem', inputs: '1.6rem' },
    bodySize: 5,
    headingSize: 2,
    headingFontFamily: "'Inter', sans-serif",
    bodyFontFamily: "'Inter', sans-serif",
    bodyLineHeight: 1.6,
    headingLineHeight: 1.6,
    bodyWeight: 300,
    headingWeight: 600,
    bodyColor: null,
    headingColor: null,
  },
  glassEffect: {
    shadow: 'none',
    blur: '0.4rem',
    borderRadius: '0.5rem',
    tableBorderRadius: '0.5rem 0.5rem 0 0',
    background: '#ffffff25',
  },
  breakpoints: {
    xs: {
      maxWidth: '575px',
    },
    sm: {
      minWidth: '576px',
      maxWidth: '767px',
    },
    md: {
      minWidth: '768px',
      maxWidth: '991px',
    },
    lg: {
      minWidth: '992px',
      maxWidth: '1199px',
    },
    xl: {
      minWidth: '1200px',
    },
  },
};

const makeThemeFromColors = (colors, isDark) => {
  const { rainbow } = normalizeTheme({
    rainbow: {
      palette: {
        brand: colors.primary,
        // mainBackground: null,
      },
    },
  });
  return {
    ...baseTheme,
    colors,
    rainbow,
    isDark,
  };
};

const lightColors = {
  primary: '#7B1AF7', // '#7653C9',
  primaryHover: '#994EF9',
  secondary: '#FFA236',
  primaryLowOpacity: '#7B1AF71F',
  tabSwitchUnselected: '#D7D7D7',
  tabSwitchSelected: '#ffffff',
  switchBackground: '#D7D7D7',
  background: '#D3D2D6',
  glassBackground: '#F4F4F4',
  glassSelectBackground: '#FFFFFF',
  glassBackgroundCalc: '#FFFFFF',
  placeholder: '#FFFFFF',
  text: '#222222',
  textInverse: '#eeeeee',
  tooltipInverse: '#222222',
  linkColours: { main: '#f4f4f4', alt: '#7B1AF7', mainHover: '', altHover: '' },
  warningText: '#FF7D77',
  cardBackground: rgba(lighten(0.75, '#ffffff'), 0.5),
  scrollbar: 'dark',
  tableHead: '#F4F4F4',
  tableBackground: '#F4F4F4',
  modalBackground: '#fff',
  graphColor: '#2962ff',
  secondaryGraphColor: '#FFA236',
  tertiaryGraphColor: lighten(0.1, '#FFA236'),
  pageBackground: `#eeeeee`,
  primaryGradient: `linear-gradient(270deg, #835BE0 0%, #732DCA 50%, #984DF4 100%)`,
  secondaryGradient: `linear-gradient(270deg, #835BE0 0%, #732DCA 50%, #984DF4 100%)`,
  shadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
  boxShadow: `rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px`,
};

const darkColors = {
  primary: '#FFA236',
  secondary: '#984DF4',
  primaryHover: '#FFBB6B',
  primaryLowOpacity: '#FFA2361F',
  tabSwitchUnselected: 'rgba(255,255,255,0.5)',
  tabSwitchSelected: '#333333',
  switchBackground: '#ffffff25',
  background: '#000000',
  glassBackground: '#2C3231',
  glassBackgroundCalc: 'rgba(255,255,255,0.5)',
  glassSelectBackground: '#ffffff25',
  placeholder: '#222222',
  text: '#dcdcdc',
  textInverse: '#222222',
  tooltipInverse: '#222222',
  linkColours: {
    main: '#222222',
    alt: '#FFA236',
    mainHover: lighten(0.25, '#222222'),
    altHover: lighten(0.1, '#FFA236'),
  },
  warningText: '#cc0000',
  cardBackground: '#2C3231',
  scrollbar: 'light',
  tableHead: '#2a3231',
  tableBackground: '#2a3231',
  modalBackground: '#070f0e',
  modal2Background: '#2C3231',
  graphColor: '#5aa7d6',
  secondaryGraphColor: '#8884d8',
  tertiaryGraphColor: lighten(0.1, '#8884d8'),
  pageBackground: '#000000',
  primaryGradient: `linear-gradient(90deg, #FFD065 0%, #FD8100 100%)`,
  secondaryGradient: `linear-gradient(270deg, #FFA236 0%, #FD8100 100%)`,
  shadow: 'none',
};

export const lightTheme = makeThemeFromColors(lightColors, false);
export const darkTheme = makeThemeFromColors(darkColors, true);
