import { Box } from 'atomic-layout';
import styled from 'styled-components';

const Wrapper = styled(Box)`
  z-index: 0;
  background-repeat: no-repeat;
  background-size: 100vw 800px;
  background: ${({ theme }) => theme.colors.pageBackground};
  min-height: 100vh;
`;

const Content = styled(Box)`
  z-index: 1;
`;

const Background = ({ children }) => {
  return (
    <Wrapper>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default Background;
