import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
html {
  font-size: 62.5%;
  margin: 0;
  padding: 0;
  max-width: 100vw;
  font-family: ${({ theme }) => theme.typography.bodyFontFamily};
  scrollbar-width: thin;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  overflow-x: hidden;
  max-width: 100vw;
  font-size: ${({ theme }) => theme.typography.fontSizes[5]};
}

/* hide scrollbar but allow scrolling */
body {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
}

body::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

/* hide scrollbar but allow scrolling */
#scrollbar-blogs {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-x: scroll; 

    &::-webkit-scrollbar {
      display: none;
    }
}

#body::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

section:first-of-type {
    @media screen and (max-width: 600px) {
    align-self: center !important;
    max-width: 90% !important;
    border-radius: ${({ theme }) => theme.glassEffect.borderRadius} !important;
    min-height: 350px;
  }
  min-height: 380px;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

[id$='_modal_calendar'] {
  font-size: ${({ theme }) => theme.typography.fontSizes[5]};
  button {
    font-size: ${({ theme }) => theme.typography.fontSizes[5]};
  }
  select {
    font-size: ${({ theme }) => theme.typography.fontSizes[5]} !important;
  }
  table th,td {
    font-size: ${({ theme }) => theme.typography.fontSizes[5]} !important;
  }
}

* {
  box-sizing: inherit;
}

input[readonly] {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}
`;
