import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled from 'styled-components';

import NextImage from "next/legacy/image";
import iconDark from 'images/iconDark.svg';
import iconLight from 'images/iconLight.svg';
import { IconButton } from 'components/atoms/Buttons';

const Links = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.sm.maxWidth}) {
    // gap: 1.25rem;
    display: none;
  }
`;

const NavLink = styled.a`
  color: ${({ theme, $active }) =>
    $active ? theme.colors.primary : theme.colors.text};
  text-decoration: none;
  font-size: 16px;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const NavDesktop = ({ darkMode, setDarkMode }) => {
  const { asPath } = useRouter();
  return (
    <Links>
      <Link href="/" passHref legacyBehavior>
        <NavLink $active={asPath === '/'}>Home</NavLink>
      </Link>
      <Link href="/blog" passHref legacyBehavior>
        <NavLink $active={asPath.startsWith('/blog')}>Blog</NavLink>
      </Link>
      <Link href="/contact" passHref legacyBehavior>
        <NavLink $active={asPath === '/contact'}>Contact</NavLink>
      </Link>
      <IconButton onClick={() => setDarkMode(!darkMode)} type="button">
        {darkMode ? (
          <NextImage
            key="lightIcon"
            src={iconLight}
            height="30px"
            width="30px"
            alt="Light Mode"
          />
        ) : (
          <NextImage
            key="darkIcon"
            src={iconDark}
            height="30px"
            width="30px"
            alt="Dark Mode"
          />
        )}
      </IconButton>
    </Links>
  );
};
