import styled from 'styled-components';

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  height: 30px;
`;

export const FormIconButton = styled.button`
  margin: initial;
  border: none;
  padding: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
  backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
  -webkit-backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
  border-radius: ${({ theme }) => theme.glassEffect.borderRadius};
  background: ${({ theme }) => theme.glassEffect.background};
`;

export const Button = styled.button`
  cursor: pointer;
  border: none;
  border-radius: ${({ theme }) => theme.glassEffect.borderRadius};
  background: ${({ theme, $primary }) =>
    $primary ? theme.colors.primary : theme.colors.glassSelectBackground};
  color: ${({ theme, $primary }) =>
    $primary ? theme.colors.textInverse : theme.colors.text};
  padding: ${({ $padding }) => ($padding ? $padding : '0.2rem 0.5rem')};
  min-height: ${({ $primary }) => ($primary ? 'initial' : '3.5rem')};
  height: ${({ $primary }) => ($primary ? 'initial' : '4rem')};
  -webkit-appearance: none;
`;
