import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { CgMenu, CgMenuRight } from 'react-icons/cg';
import styled, { keyframes } from 'styled-components';
import NextImage from "next/legacy/image";
import iconDark from 'images/iconDark.svg';
import iconLight from 'images/iconLight.svg';
import { IconButton } from 'components/atoms/Buttons';

const slideIn = keyframes`
0% { opacity: 0.2; top: 0px; }
100% { opacity: 1; top: 120px; }
`;

const slideOut = keyframes`
0% { opacity: 1; top: 120px; }
100% { opacity: 0.2; top: 0px; }
`;

const MenuButtonContainer = styled.div`
  display: flex;
  gap: 2rem;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.sm.maxWidth}) {
    display: none;
  }
`;

export const NavMobileMenu = ({
  displayMobileNav,
  setDisplayMobileNav,
  darkMode,
  setDarkMode,
}) => (
  <MenuButtonContainer>
    <IconButton onClick={() => setDarkMode(!darkMode)} type="button">
      {darkMode ? (
        <NextImage
          key="lightIcon"
          src={iconLight}
          height="30px"
          width="30px"
          alt="Light Mode"
        />
      ) : (
        <NextImage
          key="darkIcon"
          src={iconDark}
          height="30px"
          width="30px"
          alt="Dark Mode"
        />
      )}
    </IconButton>
    {displayMobileNav ? (
      <CgMenuRight
        size={30}
        onClick={() => setDisplayMobileNav(!displayMobileNav)}
      />
    ) : (
      <CgMenu
        size={30}
        onClick={() => setDisplayMobileNav(!displayMobileNav)}
      />
    )}
  </MenuButtonContainer>
);

const Container = styled.div`
  animation-name: ${({ $isOpen }) => ($isOpen ? slideIn : slideOut)};
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  z-index: 99;
  position: absolute;
  top: 120px;
`;

const Nav = styled.nav`
  width: 100vw;
  height: auto;
  background: ${({ theme }) => theme.colors.pageBackground};
  padding: 4rem;
  border-bottom: 3px solid ${({ theme }) => theme.colors.primary};
  z-index: 100;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 3rem;
`;

const NavLink = styled.a`
  color: ${({ theme, $active }) =>
    $active ? theme.colors.primary : theme.colors.text};
  width: 100%;
  text-align: center;
  padding: 1rem;
  font-size: ${({ theme }) => theme.typography.fontSizes[4]};
  text-decoration: none;
`;

export const NavMobile = ({ isOpen, setDisplayMobileNav }) => {
  const { asPath } = useRouter();
  return (
    <Container $isOpen={isOpen}>
      <Nav>
        <List>
          <Link href="/" passHref legacyBehavior>
            <NavLink
              $active={asPath === '/'}
              onClick={() => setDisplayMobileNav(false)}
            >
              Home
            </NavLink>
          </Link>
          <Link href="/blog" passHref legacyBehavior>
            <NavLink
              $active={asPath.startsWith('/blog')}
              onClick={() => setDisplayMobileNav(false)}
            >
              Blog
            </NavLink>
          </Link>
          <Link href="/contact" passHref legacyBehavior>
            <NavLink
              $active={asPath === '/contact'}
              onClick={() => setDisplayMobileNav(false)}
            >
              Contact
            </NavLink>
          </Link>
        </List>
      </Nav>
    </Container>
  );
};
