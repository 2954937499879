import { darkTheme, lightTheme } from 'styles/theme';
import { useEffect, useState } from 'react';

import Background from 'components/layout/background';
import * as Fathom from 'fathom-client';
import { Analytics } from '@vercel/analytics/react';
import { GlobalStyle } from 'styles/global';
import Head from 'next/head';
import HeaderComponent from 'components/layout/header';
import Layout from 'atomic-layout';
import Loader from 'components/loader';
import { SWRConfig } from 'swr';
import { ThemeProvider } from 'styled-components';
import dynamic from 'next/dynamic';
import fetcher from 'utils/swr/fetcher';
import { useRouter } from 'next/router';
import { NavMobile } from '../components/layout/header/NavMobile';
import { MittProvider } from 'react-mitt';

const FooterComponent = dynamic(() => import('components/layout/footer'));

function MyApp({
  Component,
  pageProps: { headerProps, footerProps, ...pageProps },
}) {
  const [loaded, setLoaded] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [displayMobileNav, setDisplayMobileNav] = useState(false);
  const theme = isDarkMode ? darkTheme : lightTheme;
  const router = useRouter();
  const isReady = router.isReady;
  const FATHOM_SITE_ID = process.env.FATHOM_SITE_ID || 'KZPZZZTT';
  const INCLUDED_DOMAINS = process.env.INCLUDED_DOMAINS || ['satsgoal.com', 'www.satsgoal.com'];

  const newHeaderProps = {
    ...headerProps,
    displayMobileNav,
    setDisplayMobileNav,
  };

  useEffect(() => {
    Fathom.load(FATHOM_SITE_ID, {
      includedDomains: INCLUDED_DOMAINS,
    });

    function onRouteChangeComplete() {
      Fathom.trackPageview();
    }

    // Record a pageview when route changes
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    // Unassign event listener
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, []);

  useEffect(() => {
    Layout.configure({
      defaultUnit: 'rem',
      breakpoints: theme.breakpoints,
    });
  }, [theme.breakpoints]);

  useEffect(() => {
    if (isReady && !loaded) {
      setTimeout(() => {
        setLoaded(true);
      }, 1000);
    }
  }, [isReady, loaded]);

  return (
    <ThemeProvider theme={theme}>
      <SWRConfig value={{ fetcher }}>
        <Head>
          <meta
            key="viewport"
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Head>
        <GlobalStyle />
        <MittProvider>
          <Background>
            {!loaded && <Loader {...pageProps.loaderProps} />}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                margin: '0 auto',
              }}
            >
              <HeaderComponent
                {...newHeaderProps}
                isDarkMode={isDarkMode}
                setIsDarkMode={setIsDarkMode}
              />
              {displayMobileNav && (
                <NavMobile
                  isOpen={displayMobileNav}
                  setDisplayMobileNav={setDisplayMobileNav}
                />
              )}
              <div
                style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
              >
                <Analytics />
                <Component {...pageProps} />
              </div>
              <FooterComponent {...footerProps} />
            </div>
          </Background>
        </MittProvider>
      </SWRConfig>
    </ThemeProvider>
  );
}

export default MyApp;
