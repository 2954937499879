import Link from 'next/link';
import NextImage from "next/image";
import strapi from 'utils/strapi';
import styled from 'styled-components';
import { NavDesktop } from './NavDesktop';
import { NavMobileMenu } from './NavMobile';

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  box-sizing: border-box;
`;

const WrapperInner = styled.div`
  padding: 5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1500px;
  box-sizing: border-box;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.sm.maxWidth}) {
    padding: 3rem 2rem;
    z-index: 101;
  }
`;

const Header = ({
  logoLight,
  logoDark,
  logoAltText,
  isDarkMode,
  setIsDarkMode,
  displayMobileNav,
  setDisplayMobileNav,
}) => {
  return (
    <Wrapper>
      <WrapperInner>
        <div>
          <Link href="/" passHref>

            <link rel="prefetch" href={logoLight} />
            <link rel="prefetch" href={logoDark} />
            <NextImage
              key="logoDark"
              src={logoDark}
              height={70}
              width={150}
              alt={logoAltText}
              priority
              style={{ display: isDarkMode ? 'block' : 'none' }}
            />
            <NextImage
              key="logoLight"
              src={logoLight}
              height={70}
              width={150}
              alt={logoAltText}
              priority
              style={{ display: !isDarkMode ? 'block' : 'none' }}
            />

          </Link>
        </div>
        <NavDesktop darkMode={isDarkMode} setDarkMode={setIsDarkMode} />
        <NavMobileMenu
          displayMobileNav={displayMobileNav}
          setDisplayMobileNav={setDisplayMobileNav}
          darkMode={isDarkMode}
          setDarkMode={setIsDarkMode}
        />
      </WrapperInner>
    </Wrapper>
  );
};

export default Header;

export async function getHeaderProps() {
  const { data } = await strapi('/api/header', {
    populate: ['logoLight', 'logoDark'],
  });
  const logoLight = data.attributes.logoLight.data.attributes;
  const logoDark = data.attributes.logoDark.data.attributes;

  return {
    logoLight: logoLight?.url,
    logoDark: logoDark?.url,
    logoAltText: data.attributes.logoAltText,
  };
}
