import styled, { keyframes } from 'styled-components'

import NextImage from "next/legacy/image";
import strapi from 'utils/strapi'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
`;

const AnimatedLogo = styled(NextImage)`
    animation: ${fadeIn} 3s linear;
`;

const Loader = ({ logo, logoAltText }) => {
    return (
        <div style={{ background: '#000', zIndex: '1000', position: 'fixed', width: '100%', height: '100%', minHeight: '100vh' }}>
            <div style={{ width: '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'center'}}>
                <AnimatedLogo src={logo} height="70px" width="100%" alt={logoAltText} priority />
            </div>
        </div>
    )
};

export default Loader;

export async function getLoaderProps() {
    const { data } = await strapi("/api/header", { populate: ['logoDark'] });
    const logoData = data?.attributes?.logoDark?.data.attributes;

    return {
        logo: logoData?.url,
        logoAltText: data.attributes.logoAltText,
    }
}
